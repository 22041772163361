import { Box, Typography, useTheme } from '@mui/material';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { renderContentBlocks } from '.';
import { GeneralPageQueryQuery } from '../../graphql-types';
import { ImageAboutUsBanner } from '../assets/images';
import { LL_BACKGROUND_COLOR } from '../colors';
import { HeroSection } from '../components';
import { CustomInput } from '../components/inputs/text-field';
import Layout from '../components/layout';
import { Absolute } from '../components/primitives/absolute';
import { PrimaryButton } from '../components/primitives/primary-button';

export interface GeneralPageProps {
  data: GeneralPageQueryQuery;
}

const GeneralPage = (props: GeneralPageProps) => {
  const resultData = props.data.craftGqlGeneralPagesDefaultEntry;
  let icon = resultData.heroIcon ? resultData.heroIcon[0]?.url : '';
  const [message, setMessage] = React.useState<string>('');
  const handleChange = (value: string) => {
    setMessage(value);
  };
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) navigate(`/contact-us?name=${message}`);
  };

  useEffect(() => {}, []);

  return (
    <Box>
      <HeroSection
        title={
          <div
            dangerouslySetInnerHTML={{
              __html:
                resultData.heroTitle ||
                'The missing Link<br />between buyers, agents<br />and properties'
            }}
          />
        }
        icon={icon}
        bgImage={resultData.heroBackgroundImage[0]?.url || ImageAboutUsBanner}
      />
      {resultData.body.map((item, i) => {
        return <Box key={i}>{renderContentBlocks(item, theme)}</Box>;
      })}
      {resultData.footerType == 'tellUsWhoYouAre' && (
        <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
          <Box
            sx={{
              paddingY: '120px',
              paddingBottom: '30px',
              paddingX: '20px',
              marginX: 'auto',
              maxWidth: '660px',
              width: '100%',
              textAlign: 'center',
              [theme.breakpoints.down('md')]: {
                paddingY: '30px',
                textAlign: 'center'
              }
            }}
          >
            <Typography
              variant="h1"
              fontFamily="Tropiline"
              textAlign="center"
              color="secondary.dark"
            >
              {resultData.footerHeading || 'Interested? Speak with our team to learn more'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box mt={{ xs: '30px', md: '60px' }}>
                <CustomInput
                  style={{ backgroundColor: 'white', width: '100%' }}
                  placeholder="Please tell us your name?"
                  onChange={(e) => handleChange(e.target.value)}
                  value={message}
                />
              </Box>
              <PrimaryButton type="submit" variant="contained" sx={{ mt: '30px' }}>
                Submit
              </PrimaryButton>
            </form>
          </Box>
          <Box position="relative">
            <Absolute
              height="250px"
              width="100%"
              zIndex={-1}
              bgcolor={LL_BACKGROUND_COLOR}
            ></Absolute>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const generalQuery = graphql`
  query GeneralPageQuery($slug: String!) {
    craftGqlGeneralPagesDefaultEntry(slug: { eq: $slug }) {
      title
      heroTitle
      footerType
      footerHeading
      heroIcon {
        id
        url
      }
      heroBackgroundImage {
        id
        url
      }
      body {
        ... on CraftGQL_body_australiasFastestGrowing_BlockType {
          id
          imageElement {
            url
          }
          richTextContent
          background
          typeHandle
        }
        ... on CraftGQL_body_whiteSpaceElement_BlockType {
          id
          height
          typeHandle
        }
        ... on CraftGQL_body_ribbonSection_BlockType {
          id
          textTitle
          typeHandle
          primaryButton {
            ... on CraftGQL_primaryButton_button_BlockType {
              id
              buttonLabel
              buttonDestinationUrl
            }
          }
        }
        ... on CraftGQL_body_informationBlock_BlockType {
          id
          informationRowUpdated {
            ... on CraftGQL_informationRowUpdated_informationRowUpdated_BlockType {
              id
              infoDescription
              infoImage {
                url
              }
              infoTitle
              typeHandle
            }
          }
          typeHandle
        }
        ... on CraftGQL_body_listingTeamMembersBlock_BlockType {
          id
          title
          buttonTitle
          bodyText
          listingMember {
            ... on CraftGQL_listingMember_listingMember_BlockType {
              id
              memberName
              memberProfileImage {
                url
              }
              memberDesignation
              typeHandle
            }
          }
          typeHandle
          teamMemberComponentType
        }
        ... on CraftGQL_body_quotesBlock_BlockType {
          id
          ceoMessage
          bodyText
          typeHandle
          textSubtitle
          imageElement {
            url
          }
        }
        ... on CraftGQL_body_videoGraphicText_BlockType {
          id
          videoUrl
          imageUrl
          richTextContent
          typeHandle
        }
        ... on CraftGQL_body_text_BlockType {
          id
          bodyText
          typeHandle
        }
        ... on CraftGQL_body_priceCards_BlockType {
          id
          priceCard {
            ... on CraftGQL_priceCard_priceCardDetails_BlockType {
              id
              cardButton
              cardDescription
              cardTitle
              price
              popular
              cardFor
            }
          }
          typeHandle
        }
        ... on CraftGQL_body_agentsReviewBlock_BlockType {
          id
          agentReviewCard {
            ... on CraftGQL_agentReviewCard_agentName_BlockType {
              id
              agencyLogo {
                url
              }
              agencyColorCode
              agencyName
              agentName
              videoThumbnail {
                url
              }
              videoUrl
            }
          }
          typeHandle
        }
        ... on CraftGQL_body_features_BlockType {
          id
          textTitle
          featureCard {
            ... on CraftGQL_featureCard_card_BlockType {
              id
              cardLinkText
              cardLink
              cardSubTitle
              cardTitle
              cardImage {
                url
              }
              imageBackgroundColor
            }
          }
          typeHandle
        }
        ... on CraftGQL_body_faqs_BlockType {
          id
          textTitle
          typeHandle
          uid
          faqs {
            ... on CraftGQL_faqs_faq_BlockType {
              id
              faqQuestion
              faqAnswer
              faqFor
            }
          }
        }
        ... on CraftGQL_body_australiaLeadingPointsSection_BlockType {
          id
          buyersDotPoints {
            col1
          }
          imageElement {
            url
          }
          typeHandle
          primaryButton {
            ... on CraftGQL_primaryButton_button_BlockType {
              id
              buttonDestinationUrl
              buttonLabel
            }
          }
        }
        ... on CraftGQL_body_newsLetter_BlockType {
          id
          newsLetterSection {
            ... on CraftGQL_newsLetterSection_newsLetterSection_BlockType {
              id
              newsTitle
              newsSubtitle
              newsButtonText
              newsImage {
                url
              }
              typeHandle
            }
          }
          typeHandle
        }
        ... on CraftGQL_body_illustrationBlock_BlockType {
          id
          imageElement {
            url
          }
          richTextContent
          imageDirection
          typeHandle
        }
      }
    }
  }
`;

GeneralPage.Layout = Layout;
export default GeneralPage;
